import React, { useEffect, useRef, useState } from "react";
import { Spin, Switch } from "antd";
import { ErrorMessage, Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Constants } from "../../Constants";
import { types } from "../../store/action_types";
import CustomTextInput from "../CustomTextInput";
import { UploadImage } from "../UploadImage";

const AddOrEditTokens = ({
  handleCloseModal,
  isFirstTime,
  setIsFirstTime,
  currentPT,
  isEdit,
  page,
}) => {
  const { createLoading, editLoading } = useSelector(
    (state) => state.TokensReducer
  );
  const BoastImageLink = useSelector(
    (state) => state?.BoostReducer?.uploadBoastimageAllData?.result?.imgLink
  );
  const loading = createLoading || editLoading;
  const [imageFile, setImageFile] = useState(null);
  const formikRef = useRef();
  const dispatch = useDispatch();

  const handleResetForm = () => {
    setImageFile(null);
    formikRef.current.resetForm({
      values: {
        name: "",
        symbol: "",
        iconImgUrl: "",
        contractAddress: "",
        decimals: "",
        totalTokens: "",
        blockchain: "",
        status: "",
      },
    });
  };

  useEffect(() => {
    if (isEdit) {
      formikRef.current.setFieldValue("name", currentPT.name);
      formikRef.current.setFieldValue("symbol", currentPT.symbol);
      formikRef.current.setFieldValue("iconImgUrl", currentPT.iconImgUrl);
      formikRef.current.setFieldValue(
        "contractAddress",
        currentPT.contractAddress
      );
      formikRef.current.setFieldValue("decimals", currentPT.decimals);
      formikRef.current.setFieldValue("totalTokens", currentPT.totalTokens);
      formikRef.current.setFieldValue("blockchain", currentPT.blockchain);
      formikRef.current.setFieldValue("status", currentPT.status === "active");
    } else {
      formikRef.current.setFieldValue("name", "");
      formikRef.current.setFieldValue("symbol", "");
      formikRef.current.setFieldValue("iconImgUrl", "");
      formikRef.current.setFieldValue("contractAddress", "");
      formikRef.current.setFieldValue("decimals", "");
      formikRef.current.setFieldValue("totalTokens", "");
      formikRef.current.setFieldValue("blockchain", "");
      formikRef.current.setFieldValue("status", "");
    }
  }, [currentPT]);

  return (
    <div>
      <div className="modaltitle">
        {`${isEdit ? "Edit" : "Add"}`} Parthners Tokens
      </div>
      <Formik
        key="my-form"
        initialValues={{
          name: "",
          symbol: "",
          iconImgUrl: "",
          contractAddress: "",
          decimals: "",
          totalTokens: "",
          blockchain: "",
          status: "",
        }}
        enableReinitialize={false}
        innerRef={formikRef}
        validationSchema={Constants.AddTokensSchema}
        onSubmit={(values, { resetForm }) => {
          if (isEdit) {
            const updatedBody = {
              tokenId: currentPT._id,
              name: values.name,
              symbol: values.symbol,
              contractAddress: values.contractAddress,
              decimals: values.decimals,
              totalTokens: values.totalTokens,
              blockchain: values.blockchain,
              status: values.status ? "active" : "disable",
            };
            dispatch({
              type: types.EditTokensRequest,
              payload: {
                updatedBody,
                handleCloseModal: () => {
                  handleCloseModal();
                  resetForm();
                },
                page,
              },
            });
          } else {
            let formData = new FormData();
            formData.append("name", values.name);
            formData.append("symbol", values.symbol);
            formData.append("iconImgUrl", !!imageFile ? BoastImageLink : "");
            formData.append("contractAddress", values.contractAddress);
            formData.append("decimals", values.decimals);
            formData.append("totalTokens", values.totalTokens);
            formData.append("blockchain", values.blockchain);
            formData.append("status", values.status ? "active" : "disable");

            dispatch({
              type: types.CreateTokensRequest,
              payload: {
                formData,
                handleCloseModal: () => {
                  handleCloseModal();
                  resetForm();
                },
                page,
              },
            });
          }
        }}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <div className="formContainer">
            <div className="formHeader">
              <div className="formFields">
                <div
                  style={{
                    display: "flex",
                    gap: 32,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <UploadImage
                    setImageFile={setImageFile}
                    ErrorMessage={ErrorMessage}
                    setIsFirstTime={setIsFirstTime}
                    isFirstTime={isFirstTime}
                    formikRef={formikRef}
                    isEdit={isEdit}
                    currentPool={{ icon: currentPT?.iconImgUrl }}
                    loading={loading}
                  />
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Field name="symbol">
                      {({ meta }) => (
                        <CustomTextInput
                          label={"Symbol"}
                          type="text"
                          error={meta.touched ? meta.error : null}
                          onChange={(e) => {
                            if (e.target.value.length <= 75)
                              handleChange("symbol")(e);
                          }}
                          placeHolderText={"Enter symbol..."}
                          value={values.symbol}
                          onBlur={handleBlur("symbol")}
                          showEyeIcon={false}
                          required={true}
                          disable={loading}
                        />
                      )}
                    </Field>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingBottom: 30,
                        paddingRight: 30,
                      }}
                    >
                      <label className="d-flex">Status*</label>
                      <Field name="status">
                        {() => (
                          <Switch
                            checked={values.status}
                            disabled={loading}
                            onChange={(checked) =>
                              setFieldValue("status", checked)
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>

                <Field name="name">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Name"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 25)
                          handleChange("name")(e);
                      }}
                      placeHolderText={"Enter name..."}
                      value={values.name}
                      onBlur={handleBlur("name")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                    />
                  )}
                </Field>
                <Field name="contractAddress">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Contract Address"}
                      type="text"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 75)
                          handleChange("contractAddress")(e);
                      }}
                      placeHolderText={"Enter contract Address..."}
                      value={values.contractAddress}
                      onBlur={handleBlur("contractAddress")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                    />
                  )}
                </Field>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Field name="blockchain">
                    {({ meta }) => (
                      <CustomTextInput
                        label={"Blockchain type"}
                        type="text"
                        error={meta.touched ? meta.error : null}
                        onChange={(e) => {
                          if (e.target.value.length <= 75)
                            handleChange("blockchain")(e);
                        }}
                        placeHolderText={"Enter blockchain type..."}
                        value={values.blockchain}
                        onBlur={handleBlur("blockchain")}
                        showEyeIcon={false}
                        required={true}
                        disable={loading}
                      />
                    )}
                  </Field>
                  <Field name="decimals">
                    {({ meta }) => (
                      <CustomTextInput
                        label={"Decimals"}
                        type="number"
                        error={meta.touched ? meta.error : null}
                        onChange={(e) => {
                          if (e.target.value.length <= 75)
                            handleChange("decimals")(e);
                        }}
                        placeHolderText={"Enter decimals..."}
                        value={values.decimals}
                        onBlur={handleBlur("decimals")}
                        showEyeIcon={false}
                        required={true}
                        disable={loading}
                      />
                    )}
                  </Field>
                </div>

                <Field name="totalTokens">
                  {({ meta }) => (
                    <CustomTextInput
                      label={"Total Tokens"}
                      type="number"
                      error={meta.touched ? meta.error : null}
                      onChange={(e) => {
                        if (e.target.value.length <= 75)
                          handleChange("totalTokens")(e);
                      }}
                      placeHolderText={"Enter total Tokens..."}
                      value={values.totalTokens}
                      onBlur={handleBlur("totalTokens")}
                      showEyeIcon={false}
                      required={true}
                      disable={loading}
                    />
                  )}
                </Field>
              </div>
            </div>

            <div className="formFooter">
              <button
                type="submit"
                disabled={loading}
                className="addbtn border p1060"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loading && <Spin className="spin" />}
                {isEdit ? "Edit" : "Add"}
              </button>
              <button
                type="submit"
                disabled={loading}
                className="cancelbtn border p1060"
                onClick={() => {
                  if (!isEdit) {
                    handleResetForm();
                  }
                  handleCloseModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AddOrEditTokens;
