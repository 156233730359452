import { types } from "../action_types";

const INITIAL_STATE = {
  getLoading: false,
  createLoading: false,
  editLoading: false,
  deleteLoading: false,
  tokens: null,
  pagination: null,
};

const TokensReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GetTokensRequest:
      return { ...state, getLoading: true };
    case types.GetTokensRequestSuccess:
      return {
        ...state,
        tokens: payload.tokens,
        pagination: payload.pagination,
        getLoading: false,
      };
    case types.GetTokensRequestFailure:
      return { ...state, getLoading: false };

    case types.CreateTokensRequest:
      return { ...state, createLoading: true };
    case types.CreateTokensRequestSuccess:
      return { ...state, createLoading: false };
    case types.CreateTokensRequestFailure:
      return { ...state, createLoading: false };

    case types.EditTokensRequest:
      return { ...state, editLoading: true };
    case types.EditTokensRequestSuccess:
      return { ...state, editLoading: false };
    case types.EditTokensRequestFailure:
      return { ...state, editLoading: false };

    case types.DeleteTokensRequest:
      return { ...state, deleteLoading: true };
    case types.DeleteTokensRequestSuccess:
      return { ...state, deleteLoading: false };
    case types.DeleteTokensRequestFailure:
      return { ...state, deleteLoading: false };

    default:
      return state;
  }
};
export default TokensReducer;
