import { put, takeLatest } from "redux-saga/effects";
import { types } from "../action_types";
import { GET, POST } from "../../Utils/httpServices";
import CustomToster from "../../SharedComponents/CustomToaster";

const multipartHeaders = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

function* GetTokensSaga({ payload }) {
  const { page } = payload;
  try {
    const tokensAllData_response = yield GET(
      `admin/tokens/all?page=${page}&limit=${5}&name=${
        payload?.searchText || ""
      }`
    );

    const { docs, currentPage, itemsPerPage, totalDocs, totalPages } =
      tokensAllData_response?.data.result.result;

    const tokens = docs;
    const pagination = {
      currentPage,
      itemsPerPage,
      totalDocs,
      totalPages,
    };

    if (tokensAllData_response?.status === 200) {
      yield put({
        type: types.GetTokensRequestSuccess,
        payload: { tokens, pagination },
      });
    } else {
      yield put({ type: types.GetTokensRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.GetTokensRequestFailure });
  }
}

function* CreateTokensSaga({ payload }) {
  let { formData, handleCloseModal, page } = payload;

  try {
    const createdTokensData_response = yield POST(
      "admin/tokens/add",
      formData,
      { ...multipartHeaders }
    );

    if (createdTokensData_response?.status === 200) {
      yield put({
        type: types.CreateTokensRequestSuccess,
      });
      yield put({
        type: types.GetCompaniesRequest,
        payload: { page },
      });
      CustomToster({
        type: "success",
        message: "Tokens Created Successfully",
      });
    } else {
      yield put({ type: types.CreateTokensRequestSuccess });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.CreateTokensRequestFailure });
  }
}

function* EditTokensSaga({ payload }) {
  let { updatedBody, handleCloseModal, page } = payload;

  try {
    const editTokensData_response = yield POST(
      "admin/tokens/edit",
      updatedBody
    );

    if (editTokensData_response?.status === 200) {
      yield put({
        type: types.EditTokensRequestSuccess,
      });

      yield put({
        type: types.GetTokensRequest,
        payload: { page },
      });

      CustomToster({
        type: "success",
        message: "Parthner Tokens Edited Successfully",
      });
    } else {
      yield put({ type: types.EditTokensRequestFailure });
    }
    handleCloseModal();
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.EditTokensRequestFailure });
  }
}

function* DeleteTokensSaga({ payload }) {
  const { tokenId, page } = payload;

  try {
    const deleteTokensData_response = yield POST(`admin/tokens/delete`, {
      tokenId,
    });

    if (deleteTokensData_response?.status === 200) {
      yield put({
        type: types.DeleteTokensRequestSuccess,
      });

      yield put({
        type: types.GetTokensRequest,
        payload: { page },
      });

      CustomToster({
        type: "success",
        message: "Parthner Tokens Deleted Successfully",
      });
    } else {
      yield put({ type: types.DeleteTokensRequestFailure });
    }
  } catch (e) {
    CustomToster({
      type: "error",
      message: e?.response?.data?.result?.details?.MESSAGE || e?.message,
    });
    yield put({ type: types.DeleteTokensRequestFailure });
  }
}

export default function* TokensSaga() {
  yield takeLatest(types.GetTokensRequest, GetTokensSaga);
  yield takeLatest(types.CreateTokensRequest, CreateTokensSaga);
  yield takeLatest(types.EditTokensRequest, EditTokensSaga);
  yield takeLatest(types.DeleteTokensRequest, DeleteTokensSaga);
}
